import { render, staticRenderFns } from "./logincode.vue?vue&type=template&id=821d7abc&scoped=true"
import script from "./logincode.vue?vue&type=script&lang=js"
export * from "./logincode.vue?vue&type=script&lang=js"
import style0 from "./logincode.vue?vue&type=style&index=0&id=821d7abc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "821d7abc",
  null
  
)

export default component.exports