export const SHOP_LIST = 'cdb/shop/listnear'
export const QR_CODE = 'cdb/cabinet/checkisok'
export const ZH_CODE = 'cdb/cabinet/zhorder'

export const ORDER_TEMP = 'cdb/cabinet/createorder'
export const ORDER_CZ = 'cdb/cabinet/order'
export const ORDER_CHECK = 'cdb/cabinet/ordercheck'

export const SAVED_CARD_TO_RENT = 'cdb/cabinet/savedCardToRent'
export const CREATE_VIP_ORDER = 'cdb/cabinet/createorderByVip'
export const PAYMODE = 'cdb/cabinet/check/payment' // 获取支付方式列表
