<template>
  <div class="login-wechat-warp">
    <div class="logo">
      <img src="../../assets/imgs/logo.png" alt="" />
    </div>
    <div class="login-content">
      <div class="login-title">申请获取以下权限</div>
      <div class="login-text">获得你的公开信息(昵称、头像、地区及性别)</div>
    </div>
    <div class="login-btn">
      <div class="btn" @click="getCode">授权登录</div>
    </div>
  </div>
</template>

<script>
import { USER_INFO, LOGIN_MPAY, GETAUTHCODEURL } from "../../apis/user.js"
import { mapState } from "vuex"
// import { getQueryString } from '../../utils/url.js'
export default {
  computed: {
    ...mapState(["user", "cabinet", "qrcode", "zhcode"]),
    url() {
      if (this.qrcode) {
        return `${window.location.origin}/#/?qrcode=${this.qrcode}`
      }
      if (this.zhcode) {
        return `${window.location.origin}/#/?zhcode=${this.zhcode}`
      }
      return `${window.location.origin}/#/`
    }
  },
  watch: {
    user(val, oldVal) {
      if (val) {
        this.$router.push("/")
      }
    }
  },
  methods: {
    // 获取用户信息
    loadUser() {
      this.$loading(true)
      this.$get(
        USER_INFO,
        {},
        (resp) => {
          this.$loading(false)
          if (resp.user) {
            this.$store.commit("user", resp.user)
            this.$toast(this._i18n.msg_success_login)
          } else {
            this.$confirm({
              title: this._i18n.msg_fail_title,
              content: this._i18n.msg_fail_user
            })
              .then(() => {
                this.loadUser()
              })
              .catch(() => {
                this.$store.commit("token", null)
              })
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this._i18n.msg_fail_title,
            content: error
          })
            .then(() => {
              this.loadUser()
            })
            .catch(() => {
              this.$store.commit("token", null)
            })
          console.log(error)
        }
      )
    },
    // 获取mpay code地址，前端写死就不用调用
    onAuthCode() {
      this.$loading(true)
      this.$post(
        "authCodeUrl",
        {
          url: this.url
        },
        (resp) => {
          setTimeout(() => {
            this.$loading(false)
          }, 2000)
          window.location.href = resp.data
        },
        (error) => {
          this.$loading(false)
          this.$toast(this._i18n.msg_fail_logi)
          console.log(error)
        }
      )
    },
    loginMpByCode(code) {
      this.$loading(true)
      this.$get(
        LOGIN_MPAY,
        { code },
        (resp) => {
          this.$loading(false)
          console.log("mpayresp", resp)
          if (resp.token) {
            this.$store.commit("token", resp.token)
            this.loadUser()
          } else {
            this.$toast(this._i18n.msg_fail_login)
          }
        },
        (error) => {
          this.$loading(false)
          this.$toast(this._i18n.msg_fail_login)
          console.log("mpayerr", error)
        }
      )
    },
    getCode() {
      let local = window.location.href.split("?")[0]
      console.log("截取后的", local)
      this.$post(
        GETAUTHCODEURL,
        {
          redirect_uri: local
        },
        (resp) => {
          setTimeout(() => {
            this.$loading(false)
          }, 2000)
          console.log("网页授权地址", resp)
          window.location.href = resp.data
        },
        (error) => {
          this.$loading(false)
          this.$toast(this._i18n.msg_fail_login)
          console.log(error)
        }
      )
      // let appid = 'mpljlmRZE2JJEFlfQf'
      // let appid = 'mpQvdUotLS2hE8CcIJ'
      // 获取mpay code的地址
      // window.open(`https://uatpay.macaupass.com/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=userinfo%20phoneinfo&state=STATE`)
      // window.open(`https://pay.macaupass.com/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local[0])}&response_type=code&scope=userinfo%20phoneinfo&state=STATE`)
    }
  },
  created() {
    let code = this.$route.query.code
    console.log("url1: ", window.location.href)
    console.log("code1: ", code)
    if (code) {
      this.loginMpByCode(code)
      this.$store.commit("mpayCode", code)
    }
  }
}
</script>

<style lang="less">
.login-wechat-warp {
  height: 100vh;
  background-color: #fff;
  .logo {
    padding: 60px 0;
    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .login-content {
    border-top: 1px solid #dddddd;
    padding: 20px 10px;
    margin: 0 48px;
  }
  .login-title {
    color: #333333;
    font-size: 28px;
  }
  .login-text {
    color: #5f6368;
    font-size: 24px;
  }
  .login-btn {
    padding: 20px 96px;
    .btn {
      background-color: #ffcc24;
    }
  }
}
</style>
