<template>
  <page headColor="#fff">
    <template slot="head">
      <img
        class="page-head-back"
        src="../../assets/imgs/back.png"
        alt=""
        @click="onBack"
      />
      <img class="logo" :src="headLogo && headLogo.blackLogoUrl" alt="" />
      <div class="righticon"></div>
    </template>
    <div>
      <div class="walletout-balance">
        <p class="top">
          <span>{{ i18n.balance }}</span
          ><span
            >{{
              walletList[0] ? walletList[0].pCurrencySymbol : user.pcurrencyName
            }}
            {{
              walletList[0] ? walletList[0].pBalance : user.pbalance || 0
            }}</span
          >
        </p>
        <p class="bottom">
          {{ $t("mine.deposit") }}:{{
            walletList[0] ? walletList[0].pDepositAmount : user.pyajin || 0
          }}
        </p>
      </div>
      <div class="wallet-form">
        <div class="wallet-label">{{ i18n.price_label }}</div>
        <div class="wallet-input">
          <input
            type="number"
            v-model="price"
            :placeholder="i18n.price_placeholder"
          />
        </div>
      </div>
      <!-- <div class="wallet-deposit" v-if="user && Number(user.pyajin)>0">
        <div>{{i18n.deposit}}:<span>{{user && user.pcurrencyName}}{{user && user.pyajin || 0}}</span></div>
      </div> -->
      <p class="allwithdrawal" @click="onAll()">{{ i18n.balance_all }}</p>
      <div class="walletout-btn">
        <button class="btn" @click="onSubmit">{{ i18n.apply }}</button>
      </div>
    </div>
  </page>
</template>

<script>
import { WALLET_LIST, TX_APPLY, TX_POST } from "../../apis/user.js"
import { mapState } from "vuex"
export default {
  computed: {
    ...mapState(["user", "browser", "headLogo"]),
    i18n() {
      return this.$t("wallet_out")
    },
    deposiTips() {
      return `${this.i18n.deposit_tips}`.format(this.user)
    }
  },
  data() {
    return {
      price: 0,
      walletList: []
    }
  },
  methods: {
    onBack() {
      this.$router.go(-1)
    },
    onAll() {
      if (this.walletList[0]) {
        this.price = this.walletList[0].pBalance
      } else if (this.user && this.user.pbalance) {
        this.price = this.user.pbalance
      }
    },
    getWalletist() {
      this.$loading(true)
      this.$get(
        WALLET_LIST,
        {
          agentId: "BJCD0000000000000000000000000001"
        },
        (resp) => {
          this.$loading(false)
          console.log("Walletist", resp)
          this.walletList = resp.data
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.getWalletist()
          })
          console.log(error)
        }
      )
    },
    onSubmit() {
      let balance = this.walletList[0] ? this.walletList[0].pBalance : 0
      if (isNaN(this.price) || this.price <= 0) {
        this.$toast(this.i18n.msg_fail_price)
      } else if (this.price > balance) {
        this.$toast(this.i18n.msg_fail_balance)
      } else {
        this.$loading(true)
        let url = TX_APPLY
        let param = {
          amount: this.price,
          userId: this.walletList[0].pUserId
        }
        if (this.browser) {
          url = `${TX_POST}/${this.price}/all`
          param = {}
        }
        this.$post(
          url,
          {
            ...param
          },
          (resp) => {
            this.$loading(false)
            this.$toast(this.i18n.msg_success_content)
            this.$store.commit("user", {
              ...this.user,
              pbalance: this.user.pbalance - this.price
            })
            this.price = 0
            console.log(resp)
          },
          (error) => {
            this.$loading(false)
            this.$confirm({
              title: this.i18n.msg_title,
              content: error,
              showCancel: false,
              confirmText: this.i18n.confirm
            })
          }
        )
      }
    }
  },
  created() {
    this.getWalletist()
  }
}
</script>

<style lang="less">
.wallet-form {
  background: #ffffff;
  padding: 40px 40px;
  .wallet-label {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .wallet-input {
    padding: 20px 0;
    display: flex;
    align-items: center;
    font-size: 56px;
    font-weight: 500;
    color: #333333;
    border-radius: 20px;
    box-shadow: 3px 6px 10px #e8e8e8;
    input {
      width: 100%;
      height: 68px;
      line-height: 68px;
      padding: 0 20px;
      color: #333333;
      font-size: 64px;
      border: none;
      outline: none;
    }
  }
}
.walletout-balance {
  border-radius: 30px;
  width: 80%;
  margin: 60px auto 0;
  font-size: 32px;
  font-weight: 400;
  color: #fff;
  padding: 10px 40px;
  background: linear-gradient(90deg, #cc001a 0%, #082c83 100%);
  .top {
    margin: 20px 0 20px;
    font-size: 36px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .bottom {
    margin: 0;
    font-size: 24px;
    text-align: end;
  }
}
.wallet-deposit {
  background: #ffffff;
  margin-top: 20px;
  padding: 26px 40px;
}
.allwithdrawal {
  text-align: center;
  color: #cc001a;
  font-size: 28px;
  font-weight: 500;
}
.walletout-btn {
  width: 85%;
  margin: 0 auto;
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
  button {
    width: 100%;
    border: none;
    padding: 0;
    margin: 0;
  }
}
</style>
