<template>
  <page back>
    <div class="mainbgc"></div>
    <div class="login-warp" :style="'min-height:' + clientHeight">
      <div class="renttip">
        <h1>{{ i18n.Rentpowerbank }}</h1>
        <span>{{ i18n.Rentpowerbanktip }}</span>
      </div>

      <span class="toplabel">{{ i18n.phonenumber }}</span>
      <div class="login-row">
        <div class="area" @click="isAreaView = true">
          <span>+{{ areaCode }}</span>
          <div class="area-down"></div>
        </div>
        <input
          type="number"
          v-model="phone"
          :placeholder="i18n.phone_number.format(weishu)"
        />
      </div>
      <!-- <span class="label">{{ i18n.verificationcode }}</span>
      <div class="login-row" v-if="isVipCode">
        <input type="text" v-model="invitePwd" :placeholder="i18n.invite_pwd" />
      </div>
      <div class="login-row" v-else>
        <input type="number" v-model="code" :placeholder="i18n.captcha_code" />
        <div class="login-code" @click="onCaptcha">
          <img :src="captchaUrl" />
        </div>
      </div> -->

      <div v-if="isVipCode" class="btn" @click="onLogin()">
        {{ i18n.receive }}
      </div>
      <div v-else class="btn" @click="onSendCode">{{ i18n.login }}</div>

      <div class="login-text" v-if="isVipCode">
        {{ i18n.vip_agreen_tip }}
        <span @click="onTermsService">{{ i18n.vip_agreen_service }}</span>
        {{ i18n.vip_agreen_and }}
        <span @click="onPrivacyPolicy">{{ i18n.vip_agreen_policy }}</span
        >.
      </div>
      <select-area v-model="isAreaView" @select="onSelct"></select-area>
      <login-code
        v-if="isLoginCode"
        @submit="onLogin"
        @again="onBack"
        @back="onBack"
      ></login-code>
    </div>
  </page>
</template>

<script>
import md5 from "js-md5"
import { SelectArea } from "../../components/select"
import { generateUUID } from "../../utils/index"
import { baseURL } from "../../utils/http"
import {
  USER_CAPTCHA,
  LOGIN_CODE,
  SEND_CODE,
  USER_INFO,
  LOGIN_FIREBASE,
  LOGIN_INVITE
} from "../../apis/user"
import { LoginCode } from "../../components/login"
import { mapState } from "vuex"
// import firebase from 'firebase'
// import firebaseui from 'firebaseui'
// var firebase = require('firebase')
// var firebase = require('firebase/app')

// Add the Firebase products that you want to use
// require('firebase/auth')
// require('firebase/firestore')
// var firebaseui = require('firebaseui')
// var ui = new firebaseui.auth.AuthUI(firebase.auth())
export default {
  components: {
    SelectArea,
    LoginCode
  },
  computed: {
    ...mapState(["user", "browser", "inviteCode", "lang"]),
    i18n() {
      return this.$t("login")
    },
    weishu() {
      return this.areaCode === "86" ? 11 : 8
    },
    isVipCode() {
      if (this.inviteCode) {
        if (this.inviteCode.substr(0, 3) === "VIP") {
          return "VIP"
        }
        if (this.inviteCode.substr(0, 3) === "DEP") {
          return "DEP"
        }
        if (this.inviteCode.substr(0, 3) === "CPM") {
          return "CPM"
        }
      }
      return false
    }
  },
  watch: {
    // user (val, oldVal) {
    //   if (val && !oldVal) {
    //     this.$router.push('/')
    //   }
    // },
    loadScriptVal(val, oldVal) {
      // 已经全部加载完毕
      if (val === 3) {
        let firebaseConfig = {
          apiKey: "AIzaSyDJvEnayA1chPOjfmnUKLY-Vj3ZhmRdEko",
          authDomain: "easycharge-hk.firebaseapp.com",
          databaseURL: "https://easycharge-hk.firebaseio.com",
          projectId: "easycharge-hk",
          storageBucket: "easycharge-hk.appspot.com",
          messagingSenderId: "691553243897",
          appId: "1:691553243897:web:f1e22054a1cf440834c828",
          measurementId: "G-Y9136CNQV1"
        }
        // Initialize Firebase
        firebase.initializeApp(firebaseConfig)
        firebase.analytics()
        window.ui = new firebaseui.auth.AuthUI(firebase.auth())
        this.loadUi()
      }
    }
  },
  data() {
    return {
      isAreaView: false,
      areaCode: "7",
      phone: "",
      code: "",
      captchaUrl: "",
      uuid: "",
      isLoginCode: false,
      clientHeight: "800px",
      // ui: null,
      uiConfig: {},
      loadScriptVal: 0,
      invitePwd: ""
    }
  },
  methods: {
    onReadMe() {
      const VUE_APP_PACT = `${process.env.VUE_APP_DOMAIN}/content/about.html`
      window.open(VUE_APP_PACT)
    },
    onSelct(val) {
      this.areaCode = val
    },
    onCaptcha() {
      this.code = ""
      this.uuid = generateUUID()
      this.captchaUrl = `${baseURL}${USER_CAPTCHA}?uuid=${this.uuid}`
    },
    onLogin(code) {
      this.$loading(true)
      let url = this.isVipCode ? LOGIN_INVITE : LOGIN_CODE
      this.$post(
        url,
        {
          mobile: this.areaCode + this.phone,
          areaCode: this.areaCode,
          verifyCode: code,
          inviteCode: this.inviteCode,
          invitePwd: this.invitePwd
        },
        (res) => {
          console.log(res)
          this.$loading(false)
          if (res.token) {
            this.$store.commit("token", res.token)
            this.loadUser()
          } else {
            this.$toast(this.i18n.msg)
          }
        },
        (err) => {
          console.log(err)
          this.$loading(false)
          this.$toast(err)
        }
      )
    },
    onSendCode() {
      // this.isLoginCode = true
      if (!this.phone) {
        this.$toast(this.i18nMsg.msg_fail_phone)
        return
      }
      let timp = new Date().valueOf()
      let str = timp.toString().replace(/0/g, "8")
      let mdsign = md5(str + "GZzbjkj")
      this.$loading(true)
      this.$post(
        SEND_CODE,
        {
          //   mobile: this.areaCode + this.phone,
          //   uuid: this.uuid,
          //   code: this.code
          mobile: this.areaCode + this.phone,
          timestamp: timp,
          sign: mdsign,
          useDebug: "true"
        },
        (res) => {
          console.log(res)
          this.$loading(false)
          // this.isLoginCode = true
          this.onLogin("1234")
        },
        (err) => {
          console.log(err)
          this.$loading(false)
          this.$toast(err)
        }
      )
    },
    onBack() {
      this.isLoginCode = false
      this.onCaptcha()
    },
    loadUser() {
      this.$loading(true)
      this.$get(
        USER_INFO,
        {},
        (res) => {
          this.$loading(false)
          if (res.user) {
            this.$store.commit("user", res.user)
            this.$router.push("/")
            if (this.isVipCode) {
              this.$toast(this.i18n.msg_success_login_code)
            } else {
              this.$toast(this.i18n.msg_success_login)
            }
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_user
            })
              .then(() => {
                this.loadUser()
              })
              .catch(() => {
                this.$store.commit("token", null)
              })
          }
        },
        (err) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: err,
            showCancel: false
          }).then(() => {
            this.$store.commit("token", null)
          })
        }
      )
    },
    loadUi() {
      let vm = this
      this.uiConfig = {
        callbacks: {
          signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            console.log("signInSuccessWithAuthResult", authResult, redirectUrl)
            let user = authResult.user
            user
              .getIdToken()
              .then((accessToken) => {
                vm.$post(
                  LOGIN_FIREBASE,
                  {
                    idToken: accessToken,
                    uid: authResult.user.uid,
                    displayName: authResult.user.displayName,
                    email: authResult.user.email,
                    photoUrl: authResult.user.photoURL,
                    phoneNumber: authResult.user.phoneNumber,
                    inviteCode: vm.inviteCode
                  },
                  (res) => {
                    vm.$store.commit("token", res.token)
                    vm.loadUser()
                  },
                  (err) => {
                    console.log("err", err)
                  },
                  {
                    headers: {
                      "Content-Type": "application/json"
                    }
                  }
                )
                console.log("accessToken", accessToken)
              })
              .catch((err) => {
                console.log("accessToken err", err)
                return false
              })
            // User successfully signed in.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            return false
          },
          uiShown: function () {
            // The widget is rendered.
            // Hide the loader.
            vm.$loading(false)
            // document.getElementById('loader').style.display = 'none'
          }
        },
        // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
        signInFlow: "popup",
        signInSuccessUrl: "/",
        signInOptions: [
          // Leave the lines as is for the providers you want to offer your users.
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.FacebookAuthProvider.PROVIDER_ID
          // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
          // firebase.auth.GithubAuthProvider.PROVIDER_ID,
          // firebase.auth.EmailAuthProvider.PROVIDER_ID,
          // firebase.auth.PhoneAuthProvider.PROVIDER_ID
        ],
        // Terms of service url.
        tosUrl: "https://m.godik.dk/content/user_agreement.html",
        // Privacy policy url.
        privacyPolicyUrl: "https://m.godik.dk/content/privacy_policy.html"
      }
      window.ui.start("#firebaseui-auth-container", this.uiConfig)
    },
    loadScript() {
      if (!window.firebase) {
        let loadScript = window.document.createElement("script")
        loadScript.src =
          "https://www.gstatic.com/firebasejs/7.14.3/firebase-app.js"
        let loadScript2 = window.document.createElement("script")
        loadScript2.src =
          "https://www.gstatic.com/firebasejs/7.14.3/firebase-analytics.js"
        let loadScript3 = window.document.createElement("script")
        loadScript3.src =
          "https://www.gstatic.com/firebasejs/7.6.0/firebase-auth.js"
        let loadScript4 = window.document.createElement("script")
        loadScript4.src =
          "https://cdn.firebase.com/libs/firebaseui/3.5.2/firebaseui.js"
        var head =
          window.document.head ||
          window.document.getElementsByTagName("head")[0] ||
          window.document.documentElement
        loadScript.onload = () => {
          loadScript2.onload = () => {
            this.loadScriptVal += 1
          }
          loadScript3.onload = () => {
            this.loadScriptVal += 1
          }
          loadScript4.onload = () => {
            this.loadScriptVal += 1
          }
          head.insertBefore(loadScript4, head.firstChild)
          head.insertBefore(loadScript3, head.firstChild)
          head.insertBefore(loadScript2, head.firstChild)
        }
        head.insertBefore(loadScript, head.firstChild)
      } else {
        this.loadUi()
      }
    },
    onTermsService() {
      window.open("https://m.godik.dk/content/user_agreement.html")
    },
    onPrivacyPolicy() {
      window.open("https://m.godik.dk/content/privacy_policy.html")
    }
  },
  created() {
    this.$loading(false)
    if (this.browser === "wechat") {
      this.$router.push("/login_wechat")
    } else if (this.browser === "alipay") {
      this.$router.push("/login_alipay")
    }
  },
  mounted() {
    this.onCaptcha()
    this.clientHeight = document.body.clientHeight - 45 + "px"
    // firebase.initializeApp(this.firebaseConfig)
    // firebase.analytics()
    // ui.start('#firebaseui-auth-container', this.uiConfig)
    // this.$loading(true)
    // if (!this.isVipCode) {
    //   this.loadScript()
    // }
  }
}
</script>

<style lang="less">
.login-warp {
  min-height: 90vh;
  background-color: #fff;
  position: relative;
  .renttip {
    padding-top: 20px;
    h1 {
      margin: 20px 40px 20px;
    }
    span {
      color: #757575;
      margin: 0 40px 20px;
    }
  }
  .label,
  .toplabel {
    display: block;
    font-size: 28px;
    color: #747474;
  }
  .toplabel {
    margin: 96px 40px 20px;
  }
  .label {
    margin: 40px 40px 20px;
  }
  .login-row {
    margin: 0 40px;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    box-shadow: 3px 6px 10px 4px #e8e8e8;
    .area {
      width: 100px;
      padding: 0 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    input {
      font-size: 32px;
      width: 300px;
      flex: 1;
      height: 80px;
      padding: 0 20px;
      margin: 0;
      border: none;
      outline: none;
      background-color: rgba(0, 0, 0, 0);
    }
    .area-down {
      // margin-left: 10px;
      width: 0;
      height: 0;
      border-width: 10px 10px 0;
      border-style: solid;
      border-color: black transparent transparent;
    }
    .login-code {
      width: 180px;
      height: 60px;
      margin-right: 20px;
      background-image: linear-gradient(
        to right,
        rgba(194, 194, 194, 1),
        rgba(252, 252, 252, 1)
      );
      img {
        width: 180px;
        height: 60px;
      }
    }
  }
  .btn {
    height: 100px;
    margin: 60px 40px 0;
  }
  .login-foot {
    // position: absolute;
    // left: 96px;
    // right: 96px;
    // bottom: 48px;
    padding: 20px;
    &-tips {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin: 0 60px;
      span {
        z-index: 100;
        background: #fff;
        padding: 28px;
      }
      &::after {
        position: absolute;
        content: " ";
        height: 1px;
        width: 100%;
        background-color: #000;
        z-index: 99;
      }
    }
    &-imgs {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #e4e7ed;
        margin: 0 24px;
      }
    }
  }
  .login-info {
    font-size: 24px;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    line-height: 33px;
    display: flex;
    justify-content: flex-start;
    padding: 0 96px;
  }
  .login-info .readme {
    font-weight: 500;
    color: rgba(52, 125, 255, 1);
    margin-left: 10px;
    display: inline;
  }
  .firebaseui-idp-list {
    display: flex;
    justify-content: center;
    .firebaseui-list-item {
      margin: 20px;
      .firebaseui-idp-button {
        min-width: 20px;
        min-height: 20px;
        height: auto;
        width: auto;
        padding: 16px;
        border-radius: 50%;
        .firebaseui-idp-text.firebaseui-idp-text-long {
          display: none;
        }
      }
    }
  }
  .login-text {
    color: #757575;
    font-size: 24px;
    text-align: center;
    padding: 76px;
    span {
      color: #4285f4;
    }
  }
}
</style>
