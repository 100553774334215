<template>
  <page headColor="#fff">
    <template slot="head">
      <img
        class="page-head-back"
        src="../../assets/imgs/back.png"
        alt=""
        @click="onBack"
      />
      <img class="logo" :src="headLogo && headLogo.blackLogoUrl" alt="" />
      <div class="righticon"></div>
    </template>
    <div class="wallet-balance">
      <div class="wallet-balance-warp">
        <div class="wallet-balance-label">{{ i18n.balance }}</div>
        <div class="wallet-balance-value">
          {{ walletList[0] ? walletList[0].pCurrencySymbol : "Dkr"
          }}{{ walletList[0] ? walletList[0].pBalance : 0 }}
        </div>
        <div class="wallet-balance-deposit">
          {{ i18n.deposit }}:{{
            walletList[0] ? walletList[0].pDepositAmount : user.pyajin || 0
          }}
        </div>
      </div>
      <div class="wallet-btn">
        <div @click="goRouter(`/wallet_out`)">
          <span>{{ i18n.wallet_out }}</span>
        </div>
        <div
          class="wallet_in"
          @click="
            goRouter(`/wallet_in${query && query.price ? `?${querypath}` : ''}`)
          "
        >
          <span>{{ i18n.wallet_in }}</span>
        </div>
      </div>
    </div>
    <div class="wallet-card">
      <p class="wallet-card-title">{{ i18n.mywalletcard }}</p>
      <div class="addcard" @click="saveCard()">
        <div class="left">
          <!-- <img src="../../assets/imgs/icon_coupon.png" alt="" /> -->
          <span class="addcard-msg">{{ i18n.addcard }}</span>
        </div>
        <img src="../../assets/imgs/icon_more.png" alt="" />
      </div>
    </div>
    <div class="scroll-y">
      <div
        class="card-list"
        v-for="item in cardList"
        :key="item.pId"
        @click="payMode = item"
      >
        <p class="value">
          <span class="pBrand">{{ item.pBrand }}</span>
          <span>****{{ item.pCardnumber }}</span>
        </p>
        <p class="date">{{ item.pExpirationdate }}</p>
      </div>
    </div>
  </page>
</template>

<script>
import { USER_INFO, WALLET_LIST, USER_CARD } from "../../apis/user.js"
import { SAVE_CARD, PAYMENT_METHOD_LIST } from "../../apis/payment.js"

import { mapState } from "vuex"
export default {
  computed: {
    ...mapState(["user", "headLogo"]),
    i18n() {
      return this.$t("mine")
    },
    i18nMsg() {
      return this.$t("msg")
    }
  },
  data() {
    return {
      paymethodshow: false,
      payMethodList: [],
      cardList: [],
      walletList: [],
      payMode: {
        pId: 0
      }
    }
  },
  methods: {
    onBack() {
      this.$router.push("/")
    },
    getCardList() {
      this.$loading(true)
      this.$get(
        USER_CARD,
        {},
        (resp) => {
          this.$loading(false)
          console.log("cardlist", resp)
          this.cardList = resp.data
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.getCardList()
          })
          console.log(error)
        }
      )
    },
    getWalletist() {
      this.$loading(true)
      this.$get(
        WALLET_LIST,
        {
          agentId: "BJCD0000000000000000000000000001"
        },
        (resp) => {
          this.$loading(false)
          console.log("Walletist", resp)
          this.walletList = resp.data
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.getWalletist()
          })
          console.log(error)
        }
      )
    },
    saveCard(method) {
      // this.paymethodshow = false
      this.$loading(true)
      this.$post(
        SAVE_CARD,
        {
          paymentId: "2",
          continueUrl: window.location.href,
          cancelUrl: window.location.href
        },
        (resp) => {
          this.$loading(false)
          console.log("savecard", resp)
          window.location.href = resp.data.redirectUrl
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.saveCard()
          })
          console.log(error)
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
    },
    getPayMethodList() {
      this.$get(
        PAYMENT_METHOD_LIST,
        {},
        (resp) => {
          console.log("PayMethodlist", resp)
          this.payMethodList = resp.data
        },
        (error) => {
          console.log(error)
        }
      )
    },
    loadUser() {
      this.$get(
        USER_INFO,
        {},
        (resp) => {
          if (resp.user) {
            this.$store.commit("user", resp.user)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_user,
              confirmText: this.i18nMsg.retry,
              cancelText: this.i18nMsg.cancel
            }).then(() => {
              this.loadUser()
            })
          }
        },
        (error) => {
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.loadUser()
          })
          console.log(error)
        }
      )
    },
    goRouter(path) {
      this.$router.push(path)
    }
  },
  created() {
    this.loadUser()
    this.getCardList()
    this.getWalletist()
    // this.getPayMethodList()
  }
}
</script>

<style lang="less">
.wallet-balance {
  position: relative;
  margin: 40px auto;
  width: calc(100% - 100px);
  height: 378px;
  border-radius: 36px;
  background: linear-gradient(90deg, #cc001a 0%, #082c83 100%);
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  &-warp {
    flex: 1;
    width: 100%;
    text-align: center;
  }
  &-label {
    font-size: 32px;
    width: 100%;
    padding: 24px 32px;
    text-align: left;
  }
  &-value {
    font-size: 70px;
    font-weight: bold;
  }
  &-deposit {
    font-size: 28px;
    text-align: right;
    margin: 32px 32px 0 0;
  }
}
.wallet-card {
  margin-top: 40px;
  padding: 0 50px;
  &-title {
    color: #999;
    font-size: 34px;
    margin-bottom: 20px;
  }
  .addcard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 3px 4px 10px 4px #e8e8e8;
    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        margin-right: 20px;
      }
    }
    img {
      width: 40px;
      height: 40px;
    }
  }
}
.scroll-y {
  max-height: 540px;
  margin: 20px 0 40px;
  overflow-y: scroll;
}
.card-list {
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 3px 6px 10px #e8e8e8;
  padding: 10px 20px;
  margin: 20px 50px;
  p {
    margin: 15px 0;
  }
  .value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #082c83;
    font-weight: 500;
    .pBrand {
      font-size: 40px;
    }
  }
  .date {
    color: #999;
    text-align: end;
  }
}

.wallet-btn {
  display: flex;
  width: calc(100% - 60px);
  padding: 20px 0;
  border-top: 4px solid rgba(255, 255, 255, 0.2);
  & div {
    flex: 1;
    height: 72px;
    line-height: 72px;
    text-align: center;
    margin: 0 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wallet_in {
    border-left: 2px solid #fff;
  }
  & span {
    line-height: 40px;
    padding-left: 15px;
    font-size: 28px;
    font-weight: 500;
    color: #fff;
  }
}
</style>
