<template>
  <div class="menu-side">
    <div class="menu-side-title">
      <img
        class="menu-side-title-avatar"
        :src="
          user && user.avatarUrl
            ? user.avatarUrl
            : require('../../assets/imgs/avatar.png')
        "
        alt=""
      />
      <div class="menu-side-title-info" v-if="user && user.pname">
        <!-- <img
          v-if="user && user.group_name === 'VIP会员'"
          class="mine-vip-icon"
          :src="
            lang === 'en-US'
              ? require('../../assets/imgs/vip_ioc_en.png')
              : require('../../assets/imgs/vip_ico(2).png')
          "
          alt=""
        />
        <img
          v-else-if="user && (user.pyajinBalance || Number(user.pyajin))"
          class="mine-vip-icon"
          src="../../assets/imgs/vip_icon.png"
        /> -->
        <div>{{ user.pname | decode }}</div>
      </div>
      <span v-else @click="goRouter('/login')">{{ i18n.login_tips }}</span>
    </div>
    <div v-if="isWallet" class="menu-side-row" @click="goRouter('/wallet')">
      <img src="../../assets/imgs/icon_wallet.png" alt="" />
      <span>{{ i18n.wallet }}</span>
    </div>
    <div class="menu-side-row" @click="goRouter('/order')">
      <img src="../../assets/imgs/icon_order.png" alt="" />
      <span>{{ i18n.order }}</span>
    </div>
    <!-- <div class="menu-side-row" @click="goCouponMart">
      <img src="../../assets/imgs/icon_coupon.png" alt="" />
      <span>{{ i18n.coupon }}</span>
    </div> -->
    <!-- <div class="menu-side-foot">
    </div> -->
    <div class="menu-side-row" @click="goRouter('/setting')">
      <img src="../../assets/imgs/setting.png" alt="" />
      <span>{{ i18n.setting }}</span>
    </div>
    <div class="menu-side-row" @click="goRouter('/help')">
      <img src="../../assets/imgs/help.png" alt="" />
      <span>{{ i18n.help }}</span>
    </div>
    <div class="menu-side-row" @click="onLogout">
      <img src="../../assets/imgs/logout.png" alt="" />
      <span>{{ i18n.logout }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { CDY_REMART_HOME } from "@/apis/payment"
export default {
  computed: {
    ...mapState(["user", "lang", "browser"]),
    i18n() {
      return this.$t("mine")
    },
    isWallet() {
      return this.browser === "mpay" || (this.user && this.user.p_yajin_balance)
    }
  },
  methods: {
    onLogout() {
      this.$store.commit("user", null)
      this.$store.commit("token", "")
      this.$router.push("/login")
    },
    goRouter(path) {
      this.$router.push(path)
    },
    goCouponMart() {
      this.$loading(true)
      this.$get(
        CDY_REMART_HOME,
        {},
        (res) => {
          this.$loading(false)
          console.log(res)
          window.location.href = res.data
        },
        (err) => {
          this.$loading(false)
          console.log(err)
          this.$toast(err)
        }
      )
    }
  }
}
</script>

<style lang="less">
.menu-side {
  width: 550px;
  &-title {
    font-size: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 80px 0 48px;
    &-avatar {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      margin: 0 24px 0 48px;
    }
    .mine-vip-icon {
      width: 200px;
      height: 64px;
    }
  }
  &-row {
    font-size: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100px;
    img {
      width: 40px;
      height: 40px;
      margin: 0 24px 0 68px;
    }
  }
  &-foot {
    width: 100%;
    position: absolute;
    bottom: 48px;
  }
}
</style>
