<template>
  <div style="position: relative" @click="onGoogle">
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { LOGIN_GOOGLE } from "@/apis/user"
let googleAuth2 = null
export default {
  computed: {
    ...mapState(["browser", "user", "token", "lang"])
  },
  data() {
    return {
      disabled: true
    }
  },
  methods: {
    onGoogle(googleUser) {
      if (this.disabled) {
        this.$toast("Loading...")
        return
      }
      this.$loading(true)
      googleAuth2
        .grantOfflineAccess()
        .then((authResult) => {
          console.log("authResult", authResult)
          if (authResult["code"]) {
            this.loginByGoogle(authResult["code"])
          } else {
            // There was an error.
            this.$loading(false)
            this.$toast("登录失败")
          }
        })
        .catch((error) => {
          console.log(error)
          this.$loading(false)
          this.$toast("登录失败")
        })
    },
    loginByGoogle(params) {
      this.$post(
        LOGIN_GOOGLE,
        {
          androidToken: params
        },
        (resp) => {
          this.$loading(false)
          if (resp.token) {
            this.$store.commit("token", resp.token)
            this.$store.commit("loginMode", "Google")
            this.$emit("success")
          } else {
            this.$toast("登录失败")
          }
        },
        (error) => {
          console.log(error)
          this.$loading(false)
          this.$toast("登录失败")
        }
      )
    },
    CredentialResponse(response) {
      // console.log('JWT response: ', response)
      // console.log('Encoded JWT ID token: ', response.credential)
      // console.log('Decoded JWT ID token: ', decodeURIComponent(response.credential))
      let credential = response.credential
      this.loginByGoogle(credential)
    },
    initGoogle() {
      // console.log('google2.initgoogle')
      // console.log(window.google)
      google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_APPID,
        callback: this.CredentialResponse
      })
      google.accounts.id.renderButton(document.getElementById("buttonDiv"), {
        text: "signin_with",
        logo_alignment: "center",
        locale: this.lang
      })
      google.accounts.id.prompt()
      // this.loadGoogle(document, 'script', 'google-jsplatform')
    },
    loadGoogle(d, s, id) {
      let js = ""
      let fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        this.initGoogle()
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = "https://accounts.google.com/gsi/client"
      js.onload = () => {
        // console.log('loadsuccess')
        this.initGoogle()
      }
      fjs.parentNode.insertBefore(js, fjs)
    }
  },
  mounted() {
    this.loadGoogle(document, "script", "google-jsplatform")
    // this.initGoogle()
  }
}
</script>
<style lang="less" scoped>
</style>
