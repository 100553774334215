<template>
  <div>
    <van-search
      v-model="searchValue"
      placeholder="Nearby charging stations"
      shape="round"
      :clearable="false"
      custom-class="custom-class"
      field-class="custom-class"
      background="#f5f5f5"
      :use-right-icon-slot="true"
    >
      <template #right-icon>
        <van-button round size="small" type="primary" @click="onSearch"
          >Search</van-button
        >
      </template>
    </van-search>
  </div>
</template>

  <script>
export default {
  components: {},
  props: {
    value: {
      type: String
    }
  },
  data() {
    return {
      searchValue: ""
    }
  },
  watch: {
    value(val) {
      this.searchValue = val
    },
    searchValue(val) {
      this.$emit("input", val)
    }
  },
  methods: {
    onSearch() {
      this.$emit("handleValue")
    }
  },
  created() {}
}
</script>

  <style lang="less" scoped>
.custom-class {
  padding: 0px;
}
/deep/.van-cell {
  justify-content: start;
}
/deep/.van-cell__title,
.van-cell__value {
  flex: auto !important;
}
/deep/.van-search {
  padding: 0px;
}
/deep/.van-cell__left-icon-wrap {
  // display: none;
}
/deep/.van-search__content {
  background: #fff;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.2);
}
/deep/.van-button {
  padding: 0 40rpx !important;
  font-size: 21rpx !important;
  // background: #2dbb66!important;
  // color: #fff!important;
}
/deep/.van-cell--borderless {
  display: flex;
  align-items: center;
}
/deep/ .van-icon {
  position: unset;
  width: unset;
  height: unset;
}
/deep/ .van-button--primary {
  background: linear-gradient(132deg, #1a2229 0%, #00ab46 100%);
  border: 0px;
}
</style>
