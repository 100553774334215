<template>
  <div v-if="isShow" class="popup-show-modal">
    <div class="popup-show-modal-main">
      <!-- <div class="popup-show-modal-title" v-if="config.title">{{config.title}}</div> -->
      <div class="popup-show-modal-content" v-if="config.content">{{config.content}}</div>
      <div class="popup-show-modal-btns">
        <div class="cancel" v-if="config.showCancel" @click="onCancel">{{config.cancelText}}</div>
        <div class="confirm" v-if="config.showOk" @click="onConfirm">{{config.confirmText}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    cancelColor () {
      return `color: ${this.config.cancelColor};`
    },
    confirmColor () {
      return `color: ${this.config.confirmColor};`
    }
  },
  data () {
    return {
      isShow: false,
      config: {
        title: '',
        content: '',
        showCancel: true,
        showOk: true,
        cancelText: 'No',
        cancelColor: '#00FC00',
        confirmText: 'Yes',
        confirmColor: '#fff'
      }
    }
  },
  methods: {
    onCancel () {
      this.isShow = false
    },
    onConfirm () {
      this.isShow = false
    }
  }
}
</script>

<style lang="less" scoped>
.popup-show-modal {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-family: 'Montserrat-SemiBold';
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  &-main {
    width: 50%;
    background-color: #fff;
    border-radius: 15px;
    color: #161616;
    text-align: center;
    border: 2px solid #DCDFE6;
    padding: 20px 30px 30px;
  }
  // &-title {
  //   font-family: 'Montserrat-SemiBold';
  //   font-size: 32px;
  //   line-height: 80px;
  // }
  &-content {
    font-size: 28px;
    padding: 20px 40px;
  }
  &-btns {
    font-size: 28px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
    div{
      padding: 15px 20px;
      border-radius: 15px;
    }
    .cancel{
      color: #000;
      background: #f4f5ff;
    }
    .confirm{
      color: #fff;
      background: #0b91ff;
    }
  }
}
</style>
