import zhCN from './zh-CN.js'
import enUS from './en-US.js'
// import deDE from './de-DE.js'
import ruRU from './ru-RU.js'

export default {
  locale: process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_LOCALE,
  messages: {
    'zh-CN': zhCN,
    'en-US': enUS,
    'ru-RU': ruRU
    // 'de-DE': deDE
  }
}
